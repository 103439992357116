import { Splide, SplideSlide } from '@splidejs/react-splide';

import ValeriaBorja from '../assets/images/opinions/Valeria-Borja-Ara.png';
import AgustinDiNoto from '../assets/images/opinions/Agustín-Di-Noto.png';
import TitoBenavidez from '../assets/images/opinions/Tito-Benavides-Libera.png';
import FrancoMosconi from '../assets/images/opinions/Franco-Mosconi.png';
import DiegoFiorentini from '../assets/images/opinions/Diego-Fiorentini.png';
import JenniferGonzalez from '../assets/images/opinions/Jennifer-González.png';
import AlanPopovsky from '../assets/images/opinions/Alan-Popovsky.png';
import KatitzaPatiño from '../assets/images/opinions/Katitza-Patiño-Maldonado.png';
import JoseTejada from '../assets/images/opinions/Jose-Antonio-Tejada-Gomez.png';
import MarcosDeLauretis from '../assets/images/opinions/Marcos-De-Lauretis.png';

export const Opinions = () => {
    return (
        <section className="w-full h-auto bg-white pl-[10%] pr-[10%] pb-8 overflow-hidden">
            <h1 className='text-4xl flex flex-col mb-8 md:w-[200px] lg:w-auto lg:ml-[230px] font-nexa uppercase'>
                <span className='text-primary-color'>Qué opinan</span>
                <span className='text-secondary-color'>ellos</span>
            </h1>
            <Splide
                options={{
                    perPage: 2,
                    type: 'loop',
                    // direction: 'ttb',
                    // height   : '30rem',
                    // autoWidth: true,
                    autoplay: true,
                    // focus:'center',
                    // gap: 30,
                    interval: 4000,
                    easing: 'cubic-bezier(0.22, 1, 0.36, 1)',
                    speed: 800,
                    padding: '0rem',
                    arrows: false,
                    pagination: false,
                    breakpoints: {
                        1600: {
                            perPage: 1
                        },
                        1024: {
                            perPage: 1
                        },
                        780: {
                            perPage: 1
                        }
                    },
                }}
                className='p-0 md:p-8'
            >
                <SplideSlide>
                    <div className="flex flex-col lg:flex-row gap-8 items-start">
                        <img className='w-[170px] h-[170px]' src={ValeriaBorja} alt="Valeria Borja" />
                        <div className='pr-8 [&>p]:text-justify'>
                            <p>Manuel es socio en la agencia de pay media que actualmente es encargada de la inversión digital de la empresa en que trabajo.</p>
                            <p>Como cliente y por nuestra experiencia laboral de varios años trabajando juntos, conozco la calidad de profesional y persona que es Manuel. Cuenta con muchísima experiencia en marketing digital, encargado de la planificación anual y sobre todo del constante monitoreo de la eficiencia en inversión en el entorno digital.</p>
                            <p className='font-medium mt-8'>Valeria Borja Ara</p>
                            <p>Subgerente de Marketing Digital Samsung Bolivia</p>
                        </div>
                    </div>
                </SplideSlide>
                <SplideSlide>
                    <div className="flex flex-col lg:flex-row gap-8 items-start">
                        <img className='w-[170px] h-[170px]' src={AgustinDiNoto} alt="Agustin Di Noto" />
                        <div className='pr-8 [&>p]:text-justify'>
                            <p>Manu es una persona de resultados, su amplia experiencia en el campo le permite llegar a los objetivos planteados sin dificultad alguna. Aprendí de él mucho sobre cómo planificar una estrategia digital de manera integral, la lectura de métricas claves y distintas herramientas digitales. Trabajar con Manu es una garantía, lo recomiendo.</p>
                            <p className='font-medium mt-8'>Agustín Di Noto</p>
                            <p>Publicista & UX Designer</p>
                        </div>
                    </div>
                </SplideSlide>
                <SplideSlide>
                    <div className="flex flex-col lg:flex-row gap-8 items-start">
                        <img className='w-[170px] h-[170px]' src={TitoBenavidez} alt="Tito Benavidez" />
                        <div className='pr-8 [&>p]:text-justify'>
                            <p>Manu es un profesional con gran experiencia dentro del Marketing Digital, especialmente en Medios de pagos multiplataforma, gran analista y sobre todo gran persona. Su evolución diaria lo hace solido para afrontar el cambiante y rápido mundo del Marketing Online.Es un honor trabajar y aprender de el, la paciencia y capacidad de colaborar a compañeros y clientes es admirable. Conquistamos Bolivia juntos y espero seguir brindando servicios para Bolivia y el mundo juntos.TB - CEO SMID AGENCY.</p>
                            <p className='font-medium mt-8'>Tito Benavides Libera</p>
                            <p>CEO Smid Media Center</p>
                        </div>
                    </div>
                </SplideSlide>
                <SplideSlide>
                    <div className="flex flex-col lg:flex-row gap-8 items-start">
                        <img className='w-[170px] h-[170px]' src={FrancoMosconi} alt="Franco Mosconi" />
                        <div className='pr-8 [&>p]:text-justify'>
                            <p>Manuel es una persona muy profesional en su trabajo, siempre dispuesto a mejorar sus estrategias para lograr objetivos.Su amplio conocimiento en diferentes plataformas digitales hacen de Manuel un pilar imprescindible para cualquier equipo.</p>
                            <p className='font-medium mt-8'>Franco Mosconi</p>
                            <p></p>
                        </div>
                    </div>
                </SplideSlide>
                <SplideSlide>
                    <div className="flex flex-col lg:flex-row gap-8 items-start">
                        <img className='w-[170px] h-[170px]' src={DiegoFiorentini} alt="Diego Fiorentini" />
                        <div className='pr-8 [&>p]:text-justify'>
                            <p>He tenido la suerte de compartir el trabajo diario con Manuel. Él fue un recurso modelo el tiempo que cumplió tareas en el equipo que lidero. No solo superó consistentemente los objetivos que se le propusieron sino que también sumo valor agregado en todos y cada uno de ellos. Desarrollo reportes de producción y apalanco su rendimiento en el estudio, comprensión, diseño y ejecución de estrategias de marketing para el mercado on-line en LaTAM.</p>
                            <p className='font-medium mt-8'>Diego Fiorentini</p>
                            <p>Business Manager Google Adwords</p>
                        </div>
                    </div>
                </SplideSlide>
                <SplideSlide>
                    <div className="flex flex-col lg:flex-row gap-8 items-start">
                        <img className='w-[170px] h-[170px]' src={JenniferGonzalez} alt="Jennifer González" />
                        <div className='pr-8 [&>p]:text-justify'>
                            <p>Manu es excelencia. Fui alumna en su curso de LinkedIn Ads es responsable, apasionado y muy claro en los conceptos que brinda. Contagia curiosidad y ganas de dar siempre un paso hacia adelante. Tiene solidos conocimientos en Campañas ADS.</p>
                            <p className='font-medium mt-8'>Jennifer González</p>
                            <p></p>
                        </div>
                    </div>
                </SplideSlide>
                <SplideSlide>
                    <div className="flex flex-col lg:flex-row gap-8 items-start">
                        <img className='w-[170px] h-[170px]' src={AlanPopovsky} alt="Alan Popovsky" />
                        <div className='pr-8 [&>p]:text-justify'>
                            <p>Manuel Vera ha demostrado ser un gran profesional con habilidades de gestión de equipos, proyectos y trabajos por objetivos. Manuel Vera tiene conocimientos avanzados en Marketing Digital, como especialista en Campañas Online, abierto siempre a adquirir nuevos conocimientos y actualizaciones, permitiendo transmitir los valores de nuestros productos y servicios con total responsabilidad y profesionalismo.</p>
                            <p className='font-medium mt-8'>Alan Popovsky</p>
                            <p>Head of Agency Google Argentina</p>
                        </div>
                    </div>
                </SplideSlide>
                <SplideSlide>
                    <div className="flex flex-col lg:flex-row gap-8 items-start">
                        <img className='w-[170px] h-[170px]' src={KatitzaPatiño} alt="Katitza Patiño Maldonado" />
                        <div className='pr-8 [&>p]:text-justify'>
                            <p>Manu, es mi colega y compañero desde hace más de 5 años, de él aprendí y sigo aprendiendo mucho.Cómo profesional, su experiencia y conocimiento en medios digitales nos ha permitido eficiencia en muchicimas campañas, casós de éxitos y learnings en cada una de sus gestiones.Es un profesional que evoluciona constantemente, ejecuta perfectamente la teoría con la practica y su ética es la mejor.Cómo colega y amigo, su compañerísmo y empatía son innegables.Recomiendo a Manue Vera como profesional y colega.</p>
                            <p className='font-medium mt-8'>Katitza Patiño Maldonado</p>
                            <p>Client Support Smid Media Center</p>
                        </div>
                    </div>
                </SplideSlide>
                <SplideSlide>
                    <div className="flex flex-col lg:flex-row gap-8 items-start">
                        <img className='w-[170px] h-[170px]' src={JoseTejada} alt="Jose Antonio Tejada Gomez" />
                        <div className='pr-8 [&>p]:text-justify'>
                            <p>Trabajo con Manuel Vera: trabajamos juntos en Marketing Digital hace ya 3 años. En Google equipo Eto para Latam y en Globadlity para Latam en Mobile Marketing es uno de los mejores Analistas en AdWords en: Búsqueda , Display, Youtube y Analytics quien se encargó del desarrollo de multiples proyectos en BI (Intelligent business) en varios clientes. junto con un equipo de profesionales elegidos se llevó a cabo con sobresalientes resultados para nuestros clientes.</p>
                            <p className='font-medium mt-8'>Jose Antonio Tejada Gomez</p>
                            <p></p>
                        </div>
                    </div>
                </SplideSlide>
                <SplideSlide>
                    <div className="flex flex-col lg:flex-row gap-8 items-start">
                        <img className='w-[170px] h-[170px]' src={MarcosDeLauretis} alt="Marcos De Lauretis" />
                        <div className='pr-8 [&>p]:text-justify'>
                            <p>Manuel es una persona muy comprometida con sus resultados. Resalto su humildad además de su capacidad de interpretar los negocios digitales y todo el mercado. Tiene una visión global y a su vez detallada del entorno más que interesante. Sabe relacionar las variables que hacen a éxito de un negocio y es sumamente organizado a la hora de plantear y alcanzar objetivos.</p>
                            <p className='font-medium mt-8'>Marcos De Lauretis</p>
                            <p>CEO Escuela de Gastronomia Online</p>
                        </div>
                    </div>
                </SplideSlide>
            </Splide>
        </section>
    )
}
