// import { useState } from 'react';
// import { motion, AnimatePresence, stagger, delay } from 'framer-motion';

import LogoManuLineWhite from '../assets/images/manuvera-line-white.svg';
import { Link } from 'react-router-dom';

export const Nav = () => {

	// const [show_options_menu, setShowOptionsMenu] = useState(false);

	const openMenu = () => {
		// setShowOptionsMenu(true);
	}

	// const closeMenu = () => {
	// 	setShowOptionsMenu(false);
	// }

	// const itemA = {
	// 	hidden: { scale: 0, top: 100 },
	// 	show: { scale: 1, top: 30 },
	// }

	return (
		<section className="w-full flex flex-row absolute items-center justify-around py-4">
			<Link to={'/'}>
				<img className='w-[220px]' src={LogoManuLineWhite} alt="ManuVera" />
			</Link>
			<ul className='flex-row gap-8 text-white [&>li]:cursor-pointer [&>li]:transition-all hidden md:flex'>
				<li className='hover:text-secondary-color'>Inicio</li>
				<li className='hover:text-secondary-color'>Acerca de mi</li>
				<li className='hover:text-secondary-color'>Servicios</li>
				<li className='hover:text-secondary-color'>Trabajo</li>
				<li className='hover:text-secondary-color'>Historia</li>
				<li className='hover:text-secondary-color'>
					<Link to={'/vcard'}>VCard</Link>
				</li>
			</ul>
			<div className='md:hidden cursor-pointer w-8 flex flex-col gap-[.4rem] [&>span]:w-full [&>span]:h-1 [&>span]:bg-white [&>span]:rounded'
				onClick={() => openMenu()}>
				<span></span>
				<span></span>
				<span></span>
			</div>
			{
				// <AnimatePresence>
				// 	{
						// show_options_menu && (
						// 	<motion.div
						// 		initial={{ opacity: 0 }}
						// 		animate={{ 
						// 			opacity: 1,
						// 			transition:{
						// 				staggerChildren:4,
						// 				delayChildren:3
						// 			}
						// 		 }}
						// 		exit={{ opacity: 0 }}								
						// 		className='fixed left-0 bg-primary-color top-0 w-full h-screen z-30'>
						// 		<motion.div	
						// 			className='w-8 h-8 bg-white rounded-full absolute left-12'
						// 			variants={itemA}
						// 		/>
						// 		<motion.div		
						// 			className='w-8 h-8 bg-white rounded-full absolute'							
						// 			variants={itemA}
						// 		/>
						// 		<motion.div			
						// 			className='w-8 h-8 bg-white rounded-full absolute'						
						// 			variants={itemA}
						// 		/>
						// 		<motion.div		
						// 			className='w-8 h-8 bg-white rounded-full absolute'							
						// 			variants={itemA}
						// 		/>
						// 	</motion.div>
						// )
				// 	}
				// </AnimatePresence>
			}

		</section>
	)
}
