import { useState } from "react";
import { Form, redirect } from "react-router-dom";
import { useLoaderData } from "react-router-dom";
import { Button, Drawer, IconButton, Input } from "@material-tailwind/react";
import {
    FacebookShareButton,
    FacebookIcon,
    WhatsappShareButton,
    WhatsappIcon,
    LinkedinShareButton,
    LinkedinIcon,
    TelegramShareButton,
    TelegramIcon,
    TwitterShareButton,
    TwitterIcon,
} from "react-share";
import { ModeEdit } from "@mui/icons-material";

import { getCardData, updateCardData } from "../api/card_data";
import { QRCodeCanvas } from "qrcode.react";

export async function loader() {
    const data = await getCardData();
    return data;
}

export async function action({ request }) {
    const formData = await request.formData();
    // const data = Object.fromEntries(formData);
    // console.log("DATOS", data);
    const respuesta = await updateCardData(formData);
    console.log("RESPUESTA DESPUES DE ACTUALIZAR", respuesta);
    return redirect('/vcard');
}

export default function VCardEdition() {
    const card_data = useLoaderData();
    console.log('card_data', card_data);

    // DRAWER
    const [open, setOpen] = useState(false);
    const openDrawer = () => setOpen(true);
    const closeDrawer = () => setOpen(false);

    const [file, setFile] = useState();
    const fileSelectedHandler = event => {
        setFile(URL.createObjectURL(event.target.files[0]))
    }

    const downloadQRCode = () => {        
        // Generate download with use canvas and stream
        const canvas = document.getElementById("qr-gen");
        console.log('canvas', canvas);
        const pngUrl = canvas.toDataURL("image/png").replace("image/png", "image/octet-stream");
        let downloadLink = document.createElement("a");
        downloadLink.href = pngUrl;
        downloadLink.download = `vcard.png`;
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
    };

    return (
        <main className="w-full h-screen p-8 overflow-y-auto">

            <div className="flex items-center justify-start mb-8">
                <IconButton onClick={openDrawer} variant="text" ripple={true}>
                    <i className="fas fa-qrcode text-2xl" />
                </IconButton>
                <h1 className="text-2xl ml-4">Edición VCard</h1>
            </div>


            <Drawer open={open} onClose={closeDrawer} className="p-2">
                <div className="flex flex-col flex-wrap gap-8">
                    <QRCodeCanvas
                        id="qr-gen"
                        className="w-40 h-40 mt-16 border mx-auto p-4"
                        size={256}
                        level={"H"}
                        value={'https://manuvera.online/vcard'}  
                        includeMargin={true}                                        
                    />
                    <Button onClick={downloadQRCode} >Descargar QR</Button>
                    <div className="flex flex-wrap items-center justify-center gap-2 px-12">
                        <FacebookShareButton url="https://manuvera.online/vcard">
                            <FacebookIcon size={40} round={true} />
                        </FacebookShareButton>
                        <WhatsappShareButton url="https://manuvera.online/vcard">
                            <WhatsappIcon size={40} round={true} />
                        </WhatsappShareButton>
                        <LinkedinShareButton url="https://manuvera.online/vcard">
                            <LinkedinIcon size={40} round={true} />
                        </LinkedinShareButton>
                        <TelegramShareButton url="https://manuvera.online/vcard">
                            <TelegramIcon size={40} round={true} />
                        </TelegramShareButton>
                        <TwitterShareButton url="https://manuvera.online/vcard">
                            <TwitterIcon size={40} round={true} />
                        </TwitterShareButton>
                    </div>
                </div>
            </Drawer>

            <Form method="post" encType="multipart/form-data">
                <input type="hidden" name="id" defaultValue={card_data.id} />
                <input type="hidden" name="username" defaultValue={card_data.username} />
                <div className="grid gap-6 mb-6 md:grid-cols-2">
                    <div className="w-fit flex flex-col items-start col-span-1 md:col-span-2 relative">
                        <div className="mb-2 max-w-[192px] w-48 h-48 bg-transparent overflow-hidden relative rounded-full border-8">
                            <img className="object-cover h-full z-0 " src={!file ? process.env.REACT_APP_API_URL + '/get-picture-profile/' + card_data.profile_picture : file} alt="Foto de perfil" />
                        </div>
                        <label className="w-30 h-30 p-2 rounded-full bg-primary-color cursor-pointer absolute bottom-10 text-white right-2" htmlFor="profile_picture">
                            <ModeEdit />
                        </label>
                        <input className="hidden" aria-describedby="file_input_help" id="profile_picture" name="img" type="file" onChange={fileSelectedHandler} />
                        <p className="w-[192px] text-center mt-0 text-xs text-gray-500 dark:text-gray-300" id="file_input_help">PNG, JPG (MAX. 400x400px).</p>
                    </div>
                    <Input className="bg-gray-500" label="Nombre" name="name" type="text" defaultValue={card_data.name} />
                    <Input className="bg-gray-500" label="Apellido" name="first_surname" type="text" defaultValue={card_data.first_surname} />
                    <Input className="bg-gray-500" label="Profesión" name="profession" type="text" defaultValue={card_data.profession} />
                    <div className="flex flex-col gap-2 w-full">
                        <label className="text-xs text-gray-500">Fecha de nacimiento:</label>
                        <Input className="bg-gray-500" label="Día" name="birthday_day" type="text" defaultValue={card_data.birthday_day} />
                        <Input className="bg-gray-500" label="Mes" name="birthday_month" type="text" defaultValue={card_data.birthday_month} />
                        <Input className="bg-gray-500" label="Año" name="birthday_year" type="text" defaultValue={card_data.birthday_year} />
                    </div>
                    <Input className="bg-gray-500" label="Correo" name="email" type="email" defaultValue={card_data.email} />
                    <Input className="bg-gray-500" label="Teléfono" name="phone" type="phone" defaultValue={card_data.phone} />
                    <Input className="bg-gray-500" label="Celular" name="mobile" type="phone" defaultValue={card_data.mobile} />
                    <Input className="bg-gray-500" label="Website" name="web" type="url" defaultValue={card_data.web} />
                    <Input className="bg-gray-500" label="URL Facebook" name="url_facebook" type="url" defaultValue={card_data.url_facebook} />
                    <Input className="bg-gray-500" label="URL Instagram" name="url_instagram" type="url" defaultValue={card_data.url_instagram} />
                    <Input className="bg-gray-500" label="URL Twitter" name="url_twiter" type="url" defaultValue={card_data.url_twiter} />
                    <Input className="bg-gray-500" label="URL YouTube" name="url_youtube" type="url" defaultValue={card_data.url_youtube} />
                    <Input className="bg-gray-500" label="URL LinkedIn" name="url_linkedin" type="url" defaultValue={card_data.url_linkedin} />
                    <Input className="bg-gray-500" label="Texto Whatsapp" name="text_whatsapp" type="text" defaultValue={card_data.text_whatsapp} />

                </div>

                <Button type="submit" className="bg-primary-color">Guardar</Button>
            </Form>

        </main>
    )
}
