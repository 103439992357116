import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import Home from './pages/home';
import VCard , {loader as vcardLoader}from './pages/vcard';
import VCardEdition, {action as vcardEditionAction, loader as vcardEditionLoader} from './pages/vcard_edition';
import { ThemeProvider } from '@material-tailwind/react';
import Generators from './pages/generators';
const router = createBrowserRouter([
  {
    path: '/',
    element: <Home />
  },
  {
    path:'/vcard',
    element: <VCard />,    
    loader:vcardLoader,
  },
  {
    path:'/vcard/edition',
    element: <VCardEdition />,
    loader:vcardEditionLoader,
    action:vcardEditionAction,
  },
  {
    path:'/generators',
    element: <Generators />
  }
])

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <ThemeProvider>      
      <RouterProvider router={router} />
    </ThemeProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
