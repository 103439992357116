import { useState } from 'react';
import WhatsappIcon from '../assets/images/generators/whatsapp_icon.svg';
// import YouTubeIcon from '../assets/images/generators/youtube_icon.svg';
// import QRIcon from '../assets/images/generators/qr_icon.svg';
import { useAnimate } from 'framer-motion';
import Loader from '../components/loader';

export default function Generators() {
    const [scope, animate] = useAnimate();
    const [generating, setGenerating] = useState(false);
    const generate = () => {    
        setGenerating(true)    
        animate([[scope.current, {width:'64px', padding:0}], [scope.current, {width:'100px'}, {at:1}]]);
        // animate(scope.current, {width:'200px'});
    }
    return (
        <main className="w-full h-screen grid grid-cols-1 lg:grid-cols-3 font-roboto">
            <div className="p-8 flex flex-col gap-8 bg-[#3a8672]">
                <div className="flex flex-row items-center gap-2">
                    <img className="w-16" src={WhatsappIcon} alt="Whatsapp" />
                    <h1 className="text-4xl font-normal">Whatsapp</h1>
                </div>
                <div className="flex flex-col items-start gap-2">
                    <label className="text-white ml-6" htmlFor="phone">Tu número de teléfono:</label>
                    <input className="p-3 outline-none border-4 border-white bg-gray-200 rounded-full w-full text-lg" id="phone" type="phone" placeholder="+59178956234" />
                </div>
                <div className="flex flex-col items-start gap-2">
                    <label className="text-white ml-6" htmlFor="message">Tu mensaje:</label>
                    <input className="p-3 outline-none border-4 border-white bg-gray-200 rounded-full w-full text-lg" id="message" type="text" placeholder="Hola, buen día!" />
                    <small className="text-xs text-gray-100 ml-6">Máximo 120 caracteres</small>
                </div>
                <button ref={scope} className="flex justify-center items-center self-start h-16 bg-[#e2bc10] text-white rounded-full border-4 border-white px-8 text-lg"
                        onClick={()=>generate()}>
                    {
                        !generating ? 'Generar enlace' : <Loader/>}
                </button>
            </div>
            <div className="p-8">
                youtube
            </div>
            <div className="p-8">
                qr
            </div>
        </main>
    )
}
