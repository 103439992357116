import BgHome from '../assets/images/bg_home.jpg';
import Manu from '../assets/images/manu-circ.webp';
import { Nav } from './nav';

export const Presentation = () => {
  return (
    <div className="h-[600px] md:h-[800px] w-full relative">    
        <Nav />
        <img src={BgHome} alt="Home" className="object-cover h-full w-full" />
        <div className='absolute bottom-16 md:bottom-0 w-full flex flex-row justify-center items-top'>
            <img src={Manu} alt="" className="w-[300px] md:w-[500px] mr-10 z-10" />
            <div className='flex flex-col absolute z-10 -top-8 text-right items-end md:items-start md:justify-start md:text-left right-8 sm:right-16 md:right-0 md:mt-40 md:relative md:w-[240px]'>
                <span className='text-white font-roboto text-2xl w-32 md:w-full md:text-5xl'>Hola, mi nombre es</span>
                <span className='text-secondary-color font-roboto font-[700] text-2xl md:text-5xl'>Manu</span>
                <div className='overflow-hidden h-[1.5rem] md:h-[2.5rem] mt-4'>
                    <div className='animate-hi-mobile md:animate-hi h-[1.5rem] md:h-[2.5rem] flex flex-col text-white text-[1rem] md:text-[1.6rem]'>
                        <div className='flex items-center justify-end md:justify-start'>Growth Marketer</div>
                        <div className='flex items-center justify-end md:justify-start'>Digital Strategist</div>
                        <div className='flex items-center justify-end md:justify-start'>Speaker</div>
                        <div className='flex items-center justify-end md:justify-start'>Google Partner</div>
                        <div className='flex items-center justify-end md:justify-start'>Data Analyst</div>
                    </div>
                </div>
            </div>
        </div>        
        <div className='w-full overflow-hidden'>
            <svg className='absolute z-20 bottom-0 block w-[calc(300%_+_1.3px)] h-[150px]' data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
                <path fill='white' d="M985.66,92.83C906.67,72,823.78,31,743.84,14.19c-82.26-17.34-168.06-16.33-250.45.39-57.84,11.73-114,31.07-172,41.86A600.21,600.21,0,0,1,0,27.35V120H1200V95.8C1132.19,118.92,1055.71,111.31,985.66,92.83Z" className="shape-fill"></path>
            </svg>
        </div>
    </div>
  )
}
