export async function getCardData(){
    const respuesta = await fetch(process.env.REACT_APP_API_URL + '/card/' + process.env.REACT_APP_CARD_USERNAME);
    console.log('first', process.env.REACT_APP_API_URL )
    const resultado = await respuesta.json();
    return resultado?.card_data;
}

export async function getVCard(){
    const respuesta = await fetch(process.env.REACT_APP_API_URL + '/get-vcard/' + process.env.REACT_APP_CARD_USERNAME);
    const resultado = await respuesta.blob();
    return resultado;
}

export async function updateCardData(data){
    try {
        const respuesta = await fetch(process.env.REACT_APP_API_URL + '/card',{
            method:'POST',
            body: data,
            headers:{
                // 'Content-Type': 'application/json',                
            }
        });
        const resultado = await respuesta.json();    
        return resultado?.card_data;
    } catch (error) {
        console.log("ERROR", error);
    }
}